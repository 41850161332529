/**
 * Track the user browser as a client
 * Send teh data to the server encrypted.
 */
const Fingerprint2 = require('../../external/fingerprint2/2.0.6/fingerprint2.js');
const GibberishAES = require('../../external/gibberish-aes/gibberish-aes.js');


window.ofFingerprint = {
  // Timer
  requestIdleCallback: null,

  /**
   * Init scraping client info
   *
   * @param encriptionKey
   * @returns {*}
   */
  init: function (encriptionKey) {
    const config = {
      excludeScreenResolution: true, //Screen resolution  will change when you move between different screens on the same decive
      excludeAvailableScreenResolution: true, //same as above
      excludeCanvas: true, //firefox will generate different Canvas value on different screen of the same device
      excludePlugins: true,  //browser plugin will change often
      excludeLanguage: true, //this is browser language, should not affect the hash
      excludeFonts: true,  // Slows down IE/FF
      fontsFlash: true,  // Slows down IE/FF
    };

    return this.run(encriptionKey, config, 500)
  },

  /**
   * Get the client info, and call the loggeg
   *
   * @param encriptionKey
   * @param config
   * @param msWait
   */
  run: function (encriptionKey, config, msWait = 500) {
    const self = this;

    if (self.requestIdleCallback) {
      self.requestIdleCallback(function () {

        Fingerprint2.getV18(config, function (result, components) {
          self.log(encriptionKey, result, components);
          // console.log({id: result, result: components}) // an array of components: {key: ..., value: ...}
        })
      })
    } else {
      setTimeout(function () {
        Fingerprint2.getV18(config, function (result, components) {
          self.log(encriptionKey, result, components);
          // console.log({id: result, result: components}) // an array of components: {key: ..., value: ...}
        })
      }, msWait)
    }

  },

  /**
   * Log the data to the server
   *
   * @param encriptionKey
   * @param key
   * @param data
   */
  log: function (encriptionKey, key, data) {
    let json = JSON.stringify({
      key: key,
      data: data
    });
    var encrypted = GibberishAES.enc(json, encriptionKey);
    const post_data = {
      data: encrypted,
    };
    $.post("/fp", post_data);
  }
};
